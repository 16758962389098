import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Sketch"
    img_name="sketch_logo.png"
    link="https://www.sketch.com/"
    description="Sketch is the best design software for professional designers that need an advanced screen design tool that runs natively on MacOS."
    twitterHandleTool="@Sketch"
  />
)

export default Tool;


